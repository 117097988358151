@import "@/styles/shared";
/* stylelint-disable-next-line block-no-empty */
.HomeScreen {
  text-align: center;
}

.title {
  @include font-h1;
  margin-bottom: px(11);
}

.description {
  @include font-body;
  color: $blue-faded;
  max-width: px(395);
}

.termsOfUseLinkContainer {
  margin-top: px(16);
}

.termsOfUseLink {
  @include font-cta-tertiary;
}
